@import './../../1.-shared/1.2.-layout/1.2.1.-custom-nav/custom-nav.scss';

* {
  margin: 0;
  padding: 0px;
}

.card {
  margin-left: 5%;
}

.cardTop {
  border-right: 2px solid #000000;
}

.cardTextTop {
  font-size: 30px;
  outline: none;
}

.textAlignRigth {
  text-align: end;
}

.textLabelHeadboard {
  padding-bottom: 1rem;
}


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.css-1lyqkne-JoyInput-root {
  border-radius: 24px !important;
}

.css-om8c7-JoySelect-root {
  border-radius: 24px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 24px !important;
}

.MuiSelect-root {
  border-radius: 24px !important;
}

.cardObjets {
  padding-top: 1.5rem;
}

.jqx-datetimeinput {
  width: 100% !important;
}

.content-solicitude {
  margin-left: 5%;
}

.bottom-right {
  text-align: end
}

.conteint-img {
  position: relative;
  display: inline-block;
  text-align: center;
}

.conteint-img-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.borderOtherIncome {
  border-top: 1px solid blue;
  margin-top: 5vh !important;
}

// Estilos del componente resultados

.solicitude-mt-20 {
  margin-top: 20px;
}

.solicitude-mb-20 {
  margin-bottom: 20px;
}

.solicitude-mb-60 {
  margin-bottom: 60px;
}


.solicitude-principal-detail {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}

.solicitude-principal-detail-title {
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  width: 20%;
}

.solicitude-principal-detail-content {
  width: 80%;
}

.solicitude-response-icon {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: start;
  border-right-style: solid;
  border-width: 1px;
  border-right-color: #0089B5;
  padding-right: 35px;
}

.solicitude-response-detail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.solicitude-row {
  display: flex;
  flex-direction: row;
  gap: 35px;
}

.solicitude-button-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2%;
}

.solicitude-buttons-right {
  float: right;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.solicitude-button-back {
  background-color: #444444;
  color: white;
  padding: 10px 0;
  border-radius: 20px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    left: 20px;
  }
}

.solicitude-button-red {
  background-color: #EC0000;
  color: white;
  padding: 10px 0;
  border-radius: 20px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    right: 20px;
  }

}

.solicitude-button-documentation {
  background-color: #FFF;
  color: #EC0000;
  padding: 10px 0;
  border-radius: 20px;
  font-weight: 700;
  border-style: solid;
  border-color: #EC0000;
  border-width: 1px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.solicitude-exception {
  background-color: #FFF;
  border-radius: 10px;
  padding: 0 40px;
}

.solicitude-exception-title {
  padding: 30px 0;
  font-weight: 700;
  font-size: 14px;
  color: #444444;
}

.solicitude-exception-status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
  padding-bottom: 20px;

  input {
    border-radius: 100px;
    padding: 15px 20px;
    background-color: #F5F5F5;
    border-style: solid;
    border-color: #D7D7D7;
    border-width: 1px;
    font-size: 15px;
    font-weight: 400;
  }
}

.solicitude-expcetion-comment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
  padding-bottom: 20px;

  textarea {
    background-color: #FFF;
    border-style: solid;
    border-color: #D7D7D7;
    border-width: 1px;
    border-radius: 10px;
    resize: none;
    height: 140px;
    padding: 15px;
  }

  textarea:focus {
    outline: none;
  }
}

.cardAnalystProfile {
  margin-left: 5%;
}

.cardTabs {
  border-radius: 10px;
  margin-right: 2%;
  background-color: white;
  overflow: auto;
}

.cardTabs::-webkit-scrollbar {
  width: 6px; 
  height: 6px; 
}

.cardTabs::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px; 
}

.cardTabs::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.header-tab-documentation {
  color: #444444 !important;
  font-style: normal !important;
  font-weight: 750 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-transform: uppercase !important;
}

.borderOtherIncome {
  border-top: 1px solid #0089B5;
  margin-top: 5vh !important;
}

.borderOtherIncomeBottom {
  border-top: 1px solid #BDBDBD;
  margin-top: 5vh !important;
}

.content-result-documentation-add-dialog-body {
  min-height: 350px;
  max-height: 350px;
  overflow: auto;
}

.content-result-documentation-add-dialog-body::-webkit-scrollbar {
  display: none !important;
}

.content-result-documentation-add-dialog {
  padding: 10px;
  width: 350px;
  overflow: hidden;

  .content-result-documentation-add-dialog-title {

    padding: 10px;

    h2 {
      text-align: center;
      font-size: 14px;
      font-weight: 700 !important;
    }

  }

}

.icon-document-link {
  cursor: pointer;
  color: #0089B5;
}


.save-dialong-content {
  padding: 20px;
  width: 400px;
}

.save-dialog-title {
  text-align: center;
  font-size: 14px;
  font-weight: 700 !important;
  margin-bottom: 20px;
}

.save-dialog-btn-Save {
  text-align: center;
  width: 200px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #c41c1c;
  color: #FFF;
  border-radius: 100px;
  margin: auto;
}

.save-dialog-btn-close {
  text-align: center;
  width: 200px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #444444;
  color: #FFF;
  border-radius: 100px;
  margin: auto;
}

.save-dialog-list-content {
  margin-bottom: 30px;
}

.card-result {
  border-radius: 10px;
  margin-right: 2%;
  overflow: auto;
}

.text-verMas {
  font-weight: bold;
  color: #cb3831;
  text-decoration: underline;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.cardButtonItem {
  margin-top: 50px;
}

.release-exception-dialog-content {
  padding: 25px;
  max-width: 850px;
  max-height: 700px;
  border-radius: 100px;
}

.release-exception-dialog-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700 !important;
  margin-bottom: 10px;
}
.text-normal {
  font-weight: normal;
}


.table-credit-evaluation-hist {
  max-height: 400px;
  overflow: auto; 
}


.table-credit-evaluation-hist::-webkit-scrollbar {
  width: 6px; 
  height: 6px; 
}

.table-credit-evaluation-hist::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px; 
}

.table-credit-evaluation-hist::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.centrar-div {
  display: grid;
  place-items: center;   
  padding: 10px;
}